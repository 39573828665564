#tsparticles {
  height: 512px;
  width: 100%;
  min-width: 440px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0;
  z-index: 1; 
}