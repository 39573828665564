@import url('https://fonts.googleapis.com/css2?family=Courier+New&display=swap');

.fixed-width-text {
    font-family: 'Courier New', monospace;
    white-space: pre;
    line-height: 0.7;
    letter-spacing: 1px; 
    box-sizing: border-box;
    width: 400px;
    height: 400px;
    padding-top: 20px;
    color: white;
  }
  