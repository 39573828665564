.box {
  min-height: 480px;
  width: 90%;
  max-width: 896px;
  border-radius: 1rem;
  margin: -1rem 0 1rem 0;
  border: 3px solid var(--dark-main);
  background-color: var(--grey-alt);
  color: var(--dark-main);
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding: 2rem;
}

.connect {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  
}

.contact-methods {
  width: 40%
}

.method {
  padding: 1rem;
  font-size: 110%;
}

.method h2 {
  color: white;
}

.globe-animation {
  margin-top: 0.75rem;
  text-align: center;
}

/* Small Device Sizing */
@media screen and (max-width: 799px) {
  .globe-animation {
    display: none;
  }
  
  .contact-methods {
    width: auto;
    text-align: center;
  }

  .box {
    width: 384px;
  }
}