.footer .bar {
  width: 100%;
  margin-left: 0;
  justify-content: center;
}

.footer {
  color: var(--light-main);
}

.footer-notice {
  width: auto;
}

.react-icon {
  margin-bottom: -5px;
}
