@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

/*VARIABLES*/
:root {
  --dark-main: rgb(12, 12, 20);
  --dark-alt: rgb(18, 18, 26);

  --grey-main: rgb(31, 31, 37);
  --grey-alt: rgb(41, 41, 49);

  --light-main: #fafafa;
  --light-alt: #d2d3db;

  --accent-main: #007acc;
  --accent-alt: rgba(77, 181, 255, 0.4);

  --transition: all 400ms ease;

  --max-width: 1440px;
  --min-width: 440px;
}

/* GENERAL SETTINGS */
* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}


html {
  scroll-behavior: smooth;  
}

::-webkit-scrollbar {
  display: none;
}

body {
  font-family: 'Poppins', sans-serif;
  background: var(--dark-main);
  color: var(--light-main);
  line-height: 1.75;
}

/* STYLING ELEMENTS */
.content {
  width: 92.5%;
  margin: 0 auto;
  display: flex;
  position: relative;
  justify-content: space-between;
  padding: 1rem;
  min-height: 512px;
  max-width: var(--max-width);
  min-width: var(--min-width);
}

.center {
  justify-content: center;
}

.title {
  padding: 1rem;
  text-align: center;
  font-size: 150%;
  width: 100%;

}

.bar {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  align-items: center;
}

.alt {
  background: var(--dark-alt);
}

.container {
  min-width: var(--min-width);
}

a { 
  color: var(--accent-main);
  transition: var(--transition);
}

a:hover {
  color: var(--light-main);
}

.btn {
  width: max-content;
  display: inline-block;
  color: var(--accent-main);
  padding: 0.25rem 1rem;
  border-radius: 5rem;
  cursor: pointer;
  border: 1px solid var(--accent-main);
  transition: var(--transition);
}

.btn:hover{
  color: var(--light-main);
  border-color: var(--light-main);
}

p {
  font-weight: 100;
}

img {
  display: block;
  width: 100%;
  object-fit: cover;
}

