.links {
  padding: 1rem 0;
  display: flex;
  width: 250px;
  justify-content: space-between;
}

.bar {
  margin: 0 10px;
  width: 100%;
  height: 64px;
}

.center {
  display: flex;
}

.social-icons {
  margin-top: 6px;
}

.social-icons a {
  margin-left: 1.5rem;
  transition: var(--transition);
}

.logo {
  display: flex;
  height: 125%;
  padding: 1rem 0;
}

.logo img {

}

.logo-name {
  margin-left: 1rem;
}

/* Small Device Sizing */
@media screen and (max-width: 600px) {
  .logo-name {
    display: none;
  }
}