.grid {
  display: inline-grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 0px;
  justify-items: center;
}

.projects {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.more {
  margin-bottom: 3rem;
}

.btn-icon {
  margin-bottom: -0.5rem;
}